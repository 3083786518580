(function () {
    var documentRoot = '/';
    var version = '1.0.2';
    require.config({
        baseUrl: documentRoot + 'fileadmin/sites/wst_v1/assets/js',
        urlArgs: "bust=" + encodeURI(version),
        paths: {
            jquery: documentRoot + 'fileadmin/sites/wst_v1/assets/js/_libs/jquery',
            bootstrap: documentRoot + 'fileadmin/sites/wst_v1/assets/js/_libs/bootstrap'
        },
        shim: {
            "bootstrap/util": {
                deps: ["jquery"]
            },
            "bootstrap/collapse": {
                deps: ["jquery", "bootstrap/util"]
            },
            "bootstrap/tab": {
                deps: ["jquery", "bootstrap/util"]
            },
             "bootstrap/modal": {
                deps: ["jquery", "bootstrap/util"]
            }
        }
    });

    require(['modernizr'], function () {
        require(['common/all'], function () {
            require(['components/all', 'modules/all'], function () {
                console.debug("===== App ======");
            });
        })
    });

})();

// decrypt helper function
function decryptCharcode(n,start,end,offset) {
    n = n + offset;
    if (offset > 0 && n > end) {
        n = start + (n - end - 1);
    } else if (offset < 0 && n < start) {
        n = end - (start - n - 1);
    }
    return String.fromCharCode(n);
}
// decrypt string
function decryptString(enc,offset) {
    var dec = "";
    var len = enc.length;
    for(var i=0; i < len; i++) {
        var n = enc.charCodeAt(i);
        if (n >= 0x2B && n <= 0x3A) {
            dec += decryptCharcode(n,0x2B,0x3A,offset);	// 0-9 . , - + / :
        } else if (n >= 0x40 && n <= 0x5A) {
            dec += decryptCharcode(n,0x40,0x5A,offset);	// A-Z @
        } else if (n >= 0x61 && n <= 0x7A) {
            dec += decryptCharcode(n,0x61,0x7A,offset);	// a-z
        } else {
            dec += enc.charAt(i);
        }
    }
    return dec;
}
// decrypt spam-protected emails
function linkTo_UnCryptMailto(s) {
    location.href = decryptString(s,4);
}
